.custom-container {
    background-color: #FFFFFF;
    padding: 20px;
    margin: 10px 0 20px 0;
}

.custom-select,
.custom-input {
    height: 50px;
}

.custom-button {
    height: 50px;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
}

.btn-outline-primary.custom-button {
    border: 1px solid #3E67B1;
    color: #3E67B1;
}
