.OurMission,
.OurVision {
  font-family: sans-serif;
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #3a3a3a;
  opacity: 1;
  line-height: 7%;
}
.OurMissionText,
.OurVisionText {
  font-family: sans-serif;
  font-size: 18px;
  letter-spacing: 0px;
  color: #3f3f3f;
  opacity: 1;
}
