.Calendar {
	font-family: sans-serif;
	text-align: center;
}

.mycheckbox {
	transform: scale(2);
	margin-right: 11px;
}

/* override day number color and size */
.fc-day-number {
	font-size: 1.5em;
	color: #5c005c;
}

/* override day title color and size */
.fc-day-header {
	font-size: 1.5em;
	color: #00b294;
}
