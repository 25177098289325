/* .show-pass {
  position: absolute;
  top: 12px;
  right: 0.625rem;
  cursor: pointer;
}

.show-pass img {
  width: 20px;
  margin-bottom: 0;
  margin-left: 0;
}
.input-text {
  position: relative;
  padding-top: 0.625rem;
  width: 100%;
} */

.input-with-space::placeholder::before {
  content: "\00a0"; /* Unicode for a non-breaking space */
  padding-right: 10px; /* Adjust the padding based on how much space you want */
}

form i {
  /* margin-left: -50%;  */ /* It is disturbing all icons accross app */
  cursor: pointer;
}

.sortable {
  /* font-family: "Mulish", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  font-style: normal;
  font-variant: normal; */
  font-family: "Mulish";
  font-weight: 600 !important;
  font-style: normal;
}

.loginpage-heading {
  font-family: "Open sans";
  font-weight: 600 !important;
  font-style: normal;
  opacity: 1;
  font-size: 30px;
}

.breadcrumb-text {
  font-family: "Open sans";
  font-weight: 200 !important;
  font-style: normal;
  opacity: 1;
}

select {
  padding: 10px;
  padding-right: 15px;
}

.login-button .btn {
  text-transform: none;
}

.loginpage-side-heading {
  font-family: "Open sans";
  font-weight: 600 !important;
  font-style: normal;
  font-size: 16px;
}

.select-options {
  font-family: "Open sans";
  font-weight: 400 !important;
  font-style: normal;
  font-size: 14px;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}

.select-wrapper:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  /* Use your custom icon here */
  background-image: url("../../../public/Images/dropdownicon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 15px;
  height: 10px;
  pointer-events: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add custom padding to make room for the icon */
  padding-right: 30px;
  /* Other styling */
}

.react-bootstrap-table .table td {
  font-family: "Mulish";
  font-weight: 400 !important;
  font-style: normal;
}

.profile-container {
  margin-top: 12px;
  margin-right: 0px;
}

.react-bootstrap-table-pagination-list {
  /* Customizes the pagination container */
  padding: 5px;
  position:absolute;
  /* display: flex;
  justify-content: flex-end; */
  margin-left: 378px;
  margin-top: -11px;
}
.page-item.active .page-link {
  border-radius: 85%;
  background-color:  rgb(62, 103, 177);
  height: 34px;
  width:32px;
  margin-top: 2px;
}

/* Hide default text of first and last buttons */
.react-bootstrap-table-page-btns-ul .page-item:first-child .page-link,
.react-bootstrap-table-page-btns-ul .page-item:last-child .page-link {
  visibility: hidden;
}

/* Set the background images */
.react-bootstrap-table-page-btns-ul .page-item:first-child .page-link::after {
  content: "\f104";
  visibility: visible;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  font-family: "Font Awesome 5 Free"; /* Ensure the FontAwesome font is used */
  font-weight: 900;
}

.react-bootstrap-table-page-btns-ul .page-item:last-child .page-link::after {
  content: "\f105";
  visibility: visible;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

li.page-item:last-child,
li.page-item::nth-last-child(2) {
  margin: 0px;
  padding: 0px;
}

/* .react-bootstrap-table-pagination-total {
  visibility: hidden;
} */

/* button#pageDropDown {
  visibility: hidden;
} */
/* @import url("https://fonts.googleapis.com/css?family=Raleway:400,700");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Raleway, sans-serif;
}

body {
  background: linear-gradient(90deg, #c7c5f4, #776bcc);
}

.container123 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.screen {
  background: linear-gradient(90deg, #5d54a4, #7c78b8);
  position: relative;
  height: 700px;
  width: 360px;
  box-shadow: 0px 0px 24px #5c5696;
}

.screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

.screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #fff;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

.screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: #6c63ac;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(270deg, #5d54a4, #6a679e);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: #7e7bb9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.login {
  width: 320px;
  padding: 30px;
  padding-top: 156px;
}

.login__field {
  padding: 20px 0px;
  position: relative;
}

.login__icon {
  position: absolute;
  top: 30px;
  color: #7875b5;
  left: 130px;
}

.login__input {
  border: none;
  border-bottom: 2px solid #d1d1d4;
  background: none;
  padding: 10px;
  padding-left: 24px;
  font-weight: 700;
  width: 75%;
  transition: 0.2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
  outline: none;
  border-bottom-color: #6a679e;
}

.login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #d4d3e8;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #4c489d;
  box-shadow: 0px 2px 2px #5c5696;
  cursor: pointer;
  transition: 0.2s;
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
  border-color: #6a679e;
  outline: none;
}

.button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875b5;
}

.social-login {
  position: absolute;
  height: 140px;
  width: 160px;
  text-align: center;
  bottom: 0px;
  right: 0px;
  color: #fff;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-login__icon {
  padding: 20px 10px;
  color: #fff;
  text-decoration: none;
  text-shadow: 0px 0px 8px #7875b5;
}

.social-login__icon:hover {
  transform: scale(1.5);
} */
