.pie-chart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pie-chart {
    width: 50%;
    height: 250px;
}

.chart-legend {
    width: 40%;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.legend-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    font-size: 16px;
}

.legend-box {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;  /* Adjust this value to change the spacing */
    border-radius: 2px;
}

.legend-item strong {
    margin-left: 0;  /* Ensure there's no left margin */
    padding-left: 0;  /* Ensure there's no left padding */
}
