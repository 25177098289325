.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.ipanelScroll.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-1pgrkg2-MuiPaper-root-MuiDrawer-paper {
    width:216px;
}

.css-frakzk {
    border: none;
    width: 216px;
}
thead{
    width: auto;
    white-space: nowrap;
}

i[title="Edit Candidate"],i[title="View Schedule"], i[title="View Candidate"], i[title="Download Feedback"],i[title="Edit Job"],i[title="View Job"],i[title="View Interview"],i[title="Edit Interview"],i[title="View Invoice"]{
    color: #3E67B1;
}
/* table cell padding */
.table>:not(caption)>*>* {
    padding: 10px 4px;
}
.shadow-radius{
    padding: 10px 20px 20px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3E67B133;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
}
.card-subheading{
    font: normal normal normal 14px/17px Roboto;
    letter-spacing: 0px;
    color: #6A6A6A;
    opacity: 1;
}
.table-card-filters{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding : 4px 8px;
}

/* child 1 of table-card-filters*/
.table-card-filters > div:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 14px/17px Open Sans;
    letter-spacing: 0.24px;
    padding-bottom: 6px;
}
.table-card-filters > div:last-child{
    font: normal normal bold 18px/24px Open Sans;
    letter-spacing: 0px;
    color: #3A3A3A;
}
