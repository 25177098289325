.table {
  /* border-collapse: separate;
  border-spacing: 0px 8px;
  border: none; */
  border-collapse: separate;
  border-spacing: 0 4px;
}
/* .table tbody {
  white-space: noWrap;
} */
.table tr {
  margin: 5px auto;
}
.table td {
  border: none;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  background-color: white;
}
.table td:first-child {
  border-left: 1px solid #ebebeb;
}
.table td:last-child {
  border-right: 1px solid #ebebeb;
}
.table th {
  border: none;
}
.table tr:hover {
  /* transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 5px rgba(0,0,0,0.3); */
}
.ipanelScroll::-webkit-scrollbar {
  width: 5px;
}

.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  width: 300px;
}

