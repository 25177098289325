.container-page {
    background-color: #F4F6FB;
    min-height: 739px;
}
.job-view-header{
    text-align: left;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0.28px;
    color: #908686;
    
}
.custom-para{
 font: normal normal 600 16px/22px Open Sans;
letter-spacing: 0.32px;
color: #3A3A3A;
margin-top: 4px;
}
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 46px;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3E67B133;
    border-radius: 4px;
    opacity: 1;
}

.box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 154px;
    height: 46px;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3E67B133;
    border-radius: 4px;
    opacity: 1;
}

.heading {
    top: 84px;
    left: 246px;
    width: 130px;
    height: 33px;
    /* UI Properties */
    text-align: left;
    font: normal normal 600 24px/33px Open Sans;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
}

.select-template {
    float: right;
    margin-right: 0px;
    top: 101px;
    left: 1230px;
    width: 180px;
    height: 50px;
    /* UI Properties */
    border: 1px solid #3E67B1;
    background-color: #F4F6FB;
    border-radius: 4px;
    opacity: 1;
}

.modal .fade .modal-dialog {
    width: 1100px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.buttons-right {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.leftItems {
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
}

.rightItems {
    float: right;
}

.close-button {
    width: 96px;
    height: 50px;
    /* UI Properties */
    border: 1px solid #3E67B1;
    border-radius: 4px;
    opacity: 1;
}

.upload-resume {
    min-height: 170px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
}

.basic-details {
    min-height: 476px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
}

.non-editable {
    min-height: 50px;
    /* UI Properties */
    background: #eef4ff99 0% 0% no-repeat padding-box;
    border: 1px solid #cbd6eb;
    border-radius: 6px;
    opacity: 1;
}

.editable {
    min-height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
}

.drop-down {
    min-height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
}

.drop-downs {
    height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
}


.select-template {
    min-height: 50px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #3e67b1;
    color: #3e67b1;
    border-radius: 4px;
    opacity: 1;
}

.job-details {
    min-height: 490px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
}

.customer-name {
    min-height: 226px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
    margin: 20px;
}

.project-details {
    min-height: 270px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
    margin: 20px;
}

.cjd {
    min-height: 50px;
    /* UI Properties */
    background: #3e67b1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #3e67b1;
    border-radius: 4px;
    opacity: 1;
    color: white;
}

.skill-assessment {
    min-height: 598px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3E67B133;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
    margin: 20px;
}
