.candidate-view-header2 {
    text-align: left;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0.28px;
    color: #908686;
}

.custom-paragraph {
    font-size:larger;
    font-weight: 500;
    color: #3A3A3A;
    margin-top: 4px;
}
.main-header{
    text-align: left;
    font: normal normal 600 20px/27px Open Sans;
    letter-spacing: 0px;
    color: #3A3A3A;
    opacity: 1;
    margin-bottom: 20px;
}
.row_style{
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    padding: 8px;
    margin: 0px 0px 8px 0px;
}
