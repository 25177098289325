.main-container-db {
    background-color: #f4f6fb;
  }
  .jd-details-container {
    margin: 0 1% 1% 1%;
    min-height: 739px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
  }
  .first-row {
    display: flex;
    justify-content: center; /* Aligns horizontally */
    align-items: center;
    margin: 20px 10px 40px 10px;
  }
  .second-row {
    margin: 20px 10px 40px 10px;
  }
  .date-calendar {
    min-width: 300px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    opacity: 1;
  }
  .period-dd {
    min-width: 400px;
    height: 40px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c7c7c7;
    border-radius: 6px;
    opacity: 1;
  }
.Interview-Details{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  boxShadow: 0px 3px 6px #00000029;
  border: 1px solid #E8E8E8;
  borderRadius: 4px;
  opacity: 1;
  margin: 20px 0px;
  padding:0px 20px 20px;
}
.claimed{
  height: 90px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  boxShadow: 0px 3px 6px #DAE1EE33;
  border: 1px solid #E8E8E8;
  borderRadius: 4px;
  opacity: 1;
  margin: 20px 0px 0px 0px;
}
