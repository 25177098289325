.form-container {
  background-color: #3e67b1;
  height: 50%;
  width: 100%;
}

.form-table {
  float: right;
  align-items: center;
  margin: 1%;
  padding: 5%;
  background-color: whitesmoke;
}

.talk-to-us-today-text {
  font-family: sans-serif;
  letter-spacing: 0px;
  font-weight: bold;
  margin-top: 2%;
  text-align: center;
  color: #3a3a3a;
}

.talk-to-us-today-text-below-text {
  font-family: sans-serif;
  letter-spacing: 0px;
  font-size: medium;
  text-align: center;
  color: #3a3a3a;
}
