.cards-wrapper {
  display: flex;
  justify-content: center;
}

.card {
  margin: 0 0.5em;
  border: 1px;
  border-style: solid;
  border-radius: 5%;
}

.small-ratings {
  color: gold;
}

.card-text {
  text-align: center;
  justify-content: center;
}

.carousel-inner {
  padding: 0.5em;
}
.carousel-control-prev,
.carousel-control-next {
  background-color: #e1e1e1;
  width: 5vh;
  height: 5vh;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-150%);
}
@media (min-width: 768px) {
  .card img {
    height: 12em;
  }
}
