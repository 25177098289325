.footer {
    display: flex;
    justify-content: space-between;
  }
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer .leftItems {
    gap: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
  }
  .footer .rightItems {
    float: right;
  }
  .table-container {
    overflow-x: auto;
    max-width: 100%; /* Ensures the container doesn't exceed the viewport width */
    /* width: 1184px; */
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
  }
  .table-responsive {
    width: 100%;
    max-width: 100%; /* Ensures the table doesn't exceed the container's width */
  }
  .filters {
    display: inline-flex;
    align-items: center;
    width: 90px;
    height: 46px;
    background: #ffffff;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #3e67b1;
    border-radius: 4px;
    opacity: 1;
  }
  .create-candidate {
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    min-width: 117px;
    height: 46px;
    background: #3e67b1 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #3e67b1;
    border-radius: 4px;
    opacity: 1;
  }
  .applied-filters {
    margin-top: 20px;
    min-height: 106px; /* Set a minimum height for larger screens */
    height: auto;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    /*box-shadow: 0px 3px 6px #3e67b133;*/
    /*border: 1px solid #e8e8e8;*/
    border-radius: 4px;
    opacity: 1;
  }
  @media (max-width: 768px) {
    .applied-filters {
      min-height: 80px; /* Adjust height for smaller screens */
    }
  }
  @media (max-width: 480px) {
    .applied-filters {
      min-height: 60px; /* Further adjust height for even smaller screens */
    }
  }
  .applied-filters-text {
    height: 24px;
    /* UI Properties */
    /* text-align: left; */
    /* margin-left: 20px; */
    letter-spacing: 0px;
    color: #3a3a3a;
    opacity: 1;
    margin-left: 12px;
    margin-top: 12px;
  }
  .applied-filters-list {
    /* margin-top: 20px; */
    display: flex;
    flex-wrap: wrap;
  }
  .applied-filter {
    margin-left: 12px;
    min-height: 30px;
    /* UI Properties */
    background: #d5e0f3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 5px;
  }

  .panel-slots-display {
    background: #d5e0f3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }


  .upload-resume {
    min-height: 170px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .basic-details {
    min-height: 476px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
  }
  .non-editable {
    min-height: 50px;
    /* UI Properties */
    background: #eef4ff99 0% 0% no-repeat padding-box;
    border: 1px solid #cbd6eb;
    border-radius: 6px;
    opacity: 1;
  }
  .editable {
    min-height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
  }
  .drop-down {
    min-height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
  }
  .drop-downs {
    height: 50px;
    /* UI Properties */
    background: #ffffff99 0% 0% no-repeat padding-box;
    border: 1px solid #3a3a3a;
    border-radius: 6px;
    opacity: 1;
  }


  .basic-details {
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .business-details {
    min-height: 493px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .container-heading {
    min-height: 24px;
    /* UI Properties */
    text-align: left;
    font-size: large;
    letter-spacing: 0px;
    color: #3a3a3a;
    opacity: 1;
    margin-bottom: 17px;
  }
  .availability-slot {
    min-height: 129px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #aaa2a2;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
    margin-bottom: 20px;
  }
  .employment-details {
    min-height: 128px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .education-details {
    min-height: 128px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .social-profile {
    min-height: 128px;
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }
  .comments-remarks {
    min-height: 87px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }

  .candidate-details {
    /* UI Properties */
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #3e67b133;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    opacity: 1;
    padding: 20px;
  }

.skill-assessment-1{
  min-height: 488px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #3E67B133;
border: 1px solid #E8E8E8;
border-radius: 4px;
opacity: 1;
padding: 20px;
}
.scd {
  min-height: 647px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #3E67B133;
border: 1px solid #E8E8E8;
border-radius: 4px;
opacity: 1;
padding: 20px;
}
.of{
  min-height: 87px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #3E67B133;
border: 1px solid #E8E8E8;
border-radius: 4px;
opacity: 1;
padding: 20px;
}
.documents {
  min-height: 244px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #3E67B133;
border: 1px solid #E8E8E8;
border-radius: 4px;
opacity: 1;
padding: 20px;
}
