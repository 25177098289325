body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  height: 100%;
  width: 100%;
  /* overflow-y: hidden; */
  overflow-x: hidden;
}
.MuiBox-root{
  height: 100%;
  width: 100%;
}

.fa-pen-to-square{
  cursor: pointer;

}

.fa-trash-can{
  cursor: pointer;
}
.error-message{
  color: red;
  font-size: 12px;
  margin-top: 5px;
  }

.user-agreement-grid{
  display: flex;
  flex-direction: row;
 width: 100%;
 height: 100%;


}
.user-agreement-grid-header{
  flex: 1;
}
.highlighted {
  position: relative; /* Replace with your desired style */
  color: #000000; /* Replace with your desired style */
  /* Add any other desired styles */
}


.highlighted::before {
  position: absolute;
  display: block;
  width: 4px;
  top: 21px;
  left: 12px;
  height: 4px;
  border-radius: 50%;
  background: #216ba5;
  content: '';
}
/* .display-slot-time{

    display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  padding: 10px;
  margin: 10px;
}
.slot-value{
  padding: 2px;
  margin: 2px;
} */

.display-slot-time{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
  margin: 10px;
  grid-gap: 10px;
  margin-left: -10px;
}
.slot-value{
  padding: 2px;
  margin: 2px;
}


@media (min-width: 600px) {
  .display-slot-time{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .display-slot-time{
    grid-template-columns: repeat(8, 1fr);
  }
}
.slot-submit{
  display: flex;
    flex-direction: row;
    justify-content: center;
}

.slot-value>button{
  height: 40px;
  width: 100px;
}

.custom-table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.custom-table-container::-webkit-scrollbar {
  /*display: none;*/
}

.custom-table-container1 {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;



}

.custom-table-container1::-webkit-scrollbar {
  display:"visible";



}
