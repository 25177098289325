/* .navbar {
  position: static;
  align-items: center;
  cursor: pointer;
}

.navbar ul {
  display: flex;
}

.nav-list li {
  list-style: none;
  padding: 20px 15px;

  font-weight: bold;

  word-spacing: 0%;
}
.navbar img {
  width: 10%;
  margin-left: 5px;
}

.nav-list li a {
  text-decoration: none;
  color: black;
}

.nav-list li a:hover {
  color: beige;
}

.background {
  top: 0%;
  width: 100%;
  height: 12%;
  background: #ffffff;
  opacity: 1;
} */




@media (max-width: 600px) {
  .button-container {
      display: flex;
      flex-direction: column;
      margin: 20px;
  }
}
